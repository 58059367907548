<template>
  <div>
    <div class="fee-panel__inner">
      <div class="booked-ticket-body" v-for="(item, idx) in feeData" :key="idx">
        <div class="booked-flight-type">
          <p>
            <span>{{(item.segmentType=='GO' || item.journeyType==1)?'去程':'回程'}}</span>
          </p>
        </div>
        <div class="fee-panel-top" style="display: flex;">
          <img style="width:26px;height:14px;" src="../assets/logo-small.png" alt="">
          <span>{{item.marketFlightNo}}</span>
          <span>{{item.depDatetime | formatDate}}</span>
         <!-- 有无餐食 -->
          <span>
            {{
            item.meal ? '有餐食' : '无餐食'
            }}
          </span>
          <span>{{item.cabinLabel}}</span>
        </div>
        <div class="booked-ticket-panel">
          <div class="booked-flight-dep" style="width:120px">
            <p>
              <span style="margin-left: 4px;font-size:20px;">{{item.depDatetime | formatTime}}</span>
            </p>
            <p>{{item.depAirportName}}{{item.depAirportTerm}}</p>
          </div>
          <div class="flight-main-item during" style="width:150px">
            <p style="color: #aaaaaa; width: 160px;margin-top: 0px;margin-bottom:15px;">
                    {{
                      (item.stops!=null && item.stops.length > 0)
                        ? "经停 " + item.stops[0].stopName
                        : "直飞"
                    }}
            </p>
            <p style="width: 120px;padding: 0 20px;">
              <span class="during-line"></span>
              <img class="during-air" src="../assets/pass-air.png" alt="">
            </p>
            <p
              style="color:#aaa;margin-top:10px;text-align:center;width: 160px;"
            >{{diffMinutes(item.depDatetime,item.arrDatetime) | formatDateDuring}}</p>
          </div>
          <div class="booked-flight-arr" style="margin-top:20px;width:120px">
            <p style="margin-left: 4px;font-size:20px;text-align:center">
              {{item.arrDatetime |
              formatTime}}

              <span class="more-tip" style="right: unset;"> {{overRunDays(item.depDatetime,item.arrDatetime)}} </span>
            </p>
            <p style="text-align:center">{{item.arrAirportName}}{{item.arrAirportTerm}}</p>
          </div>
          <div class="booked-flight-rule">
            <el-popover class="popover" ref="pop" trigger="hover">
              <popover-content :isLuggage="true" v-if="showRule" :cabinUnit="segments!=null && segments.length>0 && segments[idx]"/>
              <PopoverContentDetail :isLuggage="true" v-else :cabinUnit="item"/>
              <div slot="reference" class="flex">
                <img src="../assets/warn.png">
                行李额及退改签规则
              </div>
            </el-popover>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import PopoverContent from "./PopoverContent";
import PopoverContentDetail from "./PopoverContentDetail";
import { orderDetail } from "@/api/newsapi";
import { CURRENT_ORDER } from "@/store/mutation-types";
export default {
  name: "book2-fee-panel",
  props: ["segments", "flightUnit", "tempOrder", "orderNo"],
  data() {
    return {
      order: null,
      showRule:false,
      feeData: null
    };
  },
  components: { PopoverContent, PopoverContentDetail },
  filters: {
    formatDate(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD");
    },
    formatTime(timestamp) {
      return moment(timestamp).format("HH:mm");
    },
    formatDateDuring(mss) {
      var hoursRound = Math.floor(mss / 60);
      var minutes = parseInt(mss % 60);
      return hoursRound + "h" + minutes + "m";
    }
  },
  watch: {
    order(newVal) {
      console.log(newVal);
    },
    tempOrder(newVal) {
      console.log(newVal);
    },
    segments(newVal) {
      this.feeData = [];
      this.segments.map(v => {
         if( v.segmentList[0].stops==null){
           v.segmentList[0].stops=[];
        }
        if(v.segmentList[0].stopList!=null && v.segmentList[0].stopList.length>0){
           v.segmentList[0].stops.push({stopName:v.segmentList[0].stopList[0].stopAirportName})
        }
        this.feeData.push(v.segmentList[0]);
      });
      console.log("数据");
      console.log(this.feeData);
    }
  },
  mounted() {
    if (this.orderNo) {
      this.getOrderDetail();
    } else {
      this.showRule=true;
      this.feeData = [];
      this.segments.map(v => {
        if( v.segmentList[0].stops==null){
           v.segmentList[0].stops=[];
        }
        if(v.segmentList[0].stopList!=null && v.segmentList[0].stopList.length>0){
           v.segmentList[0].stops.push({stopName:v.segmentList[0].stopList[0].stopAirportName})
        }
        this.feeData.push(v.segmentList[0]);
      });
      console.log('下单：', this.feeData)
    }
  },
  methods: {
    diffMinutes(deptDate, arrDate) {
      return moment(arrDate).diff(moment(deptDate), "minutes");
    },
    overRunDays(deptDate, arrDate) {
      if (
        moment(deptDate).format("YYYY-MM-DD") ==
        moment(arrDate).format("YYYY-MM-DD")
      ) {
        return "";
      } else {
        return "+1";
      }
    },
    getOrderDetail() {
      var request = { t: this.orderNo };
      orderDetail(request).then(res => {
        this.loading = false;
        if (res.success) {
          this.order = res.data;
          console.log(res.data);
          this.feeData = res.data.segments.sort((a, b) => {
            return a.journeyType - b.journeyType;
          });
          console.log('订单详情：', this.feeData)
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.fee-panel {
  width: 428px;
}

.fee-panel-group ul li {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4d4e4e;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fee-panel-group ul::before {
  position: absolute;
  top: 6px;
  left: 0;
  bottom: 6px;
  width: 2px;
  content: "";
  background-color: #d8d8d8;
}

.fee-panel-group ul {
  position: relative;
  padding-left: 10px;
}

.fee-panel-group > div {
  margin-bottom: 10px;
}

.fee-panel-group {
  padding-left: 10px;
}

.fee-panel__inner {
  // border: 1px solid #eae7e7;
  background: #ffffff;
}

.fee-panel-content {
  padding: 16px;
  background-color: #fff;
}

.fee-panel-body {
  max-height: 485px;
  overflow-y: auto;
  background-color: #ffffff;
  // border-top: 1px solid #f8f8f8;
}

.fee-panel-header {
  height: 60px;
  line-height: 60px;
  color: #4d4e4e;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  // border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.fee-panel-top {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 14px;
  img {
    margin-right: 10px;
  }

  span {
    margin-right: 10px;
  }
}

.qucheng {
  margin-left: 4px;
  color: #fff;
}

.booked-ticket-panel > div {
  line-height: 85px;
}

.booked-flight-type {
  color: #fff;
  background-color: #d70039;
  border: 1px solid #d31a1a;
  width: 15%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 12px !important;
  border-radius: 0 5px 5px 0;
  // margin-left: -18px;

  span {
    font-size: 12px;
  }
}

.booked-ticket-panel {
  // margin-bottom: 16px;
  background-color: #fff;
  display: flex;
  min-height: 130px;
  position: relative;
  padding: 0 10px;
}

.booked-ticket-body {
  // padding: 0 18px;
  font-size: 14px;
  background: #ffffff !important;
}

.booked-flight-rule {
  // width: 15%;
  position: absolute;
  bottom: 0;
}

.booked-flight-rule span {
  width: 150px;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  transition: all, 0.68s;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 30px;
}

.flight-main-item p:first-child {
  height: 22px;
  line-height: 22px;
  margin-top: 15px;
  width: 200px;
  text-align: center;
}

.flight-main-item p:nth-child(2) {
  height: 15px;
  line-height: 15px;
  text-align: center;
  margin-top: 10px;
}

.flight-main-item p:nth-child(3) {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}

.booked-flight-cabin {
  width: 15%;
}

.booked-flight-cabin span:first-child {
  margin-right: 8px;
}

.booked-flight-arr {
  // width: 30%;
}

.booked-flight-arr p:first-child {
  margin-top: 10px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
}

.booked-flight-arr p:nth-child(2) {
  height: 30px;
  line-height: 30px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px;
}

.booked-flight-arr p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}

.booked-flight-duration p:first-child {
  margin-top: 23px;
  height: 30px;
  line-height: 30px;
}

.booked-flight-duration p:nth-child(2) {
  line-height: 15px;
}

.booked-flight-duration p:last-child {
  height: 17px;
  line-height: 17px;
}

.booked-flight-duration {
  width: 15%;
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
}

.booked-flight-dep {
  text-align: center;
  margin-top: 20px;
  justify-content: space-between;
}

.booked-flight-dep p:first-child {
  margin-top: 10px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
}

.booked-flight-dep p:nth-child(2) {
  height: 15px;
  line-height: 15px;
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-top: 16px;
}

.during {
  position: relative;
  padding-top: 26px;
  width: 260px;
  &-air {
    margin-top: -10px;
    width: 32px;
    height: 32px;
  }

  &-line {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    display: block;
    margin-bottom: -6px;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-tip{
  font-size: 12px;
  position: absolute;
  top: 20px;
  right: 0;
}
</style>